<script>
export default {
	name: 'Mall',
	data() {
		return {};
	},
	methods: {
		handleClick() { }
	}
}
</script>
<template>
	<section class="section" id="mall"
		:style="{ 'background-image': 'url(' + require('@/assets/images/bg/bg18.jpg') + ')', 'background-size': 'cover', 'background-position': 'top center' }">
		<div class="bg-overlay-light"></div>
		<div class="container">
			<div class="row align-items-center">
				<!-- 弹层开始 -->
				<el-popover placement="left" title="" width="180" height="180" trigger="hover" ref="azewmshow">
					<el-image :src="require('@/assets/images/bg/android.png')" fit="fit"></el-image>
				</el-popover>
				<el-popover placement="left" title="" width="180" height="180" trigger="hover" ref="iosewmshow">
					<el-image :src="require('@/assets/images/bg/ios.png')" fit="fit"></el-image>
				</el-popover>
				<el-popover placement="left" title="" width="180" height="180" trigger="hover" ref="xcxewmshow">
					<el-image :src="require('@/assets/images/bg/xcx.png')" fit="fit"></el-image>
				</el-popover>
				<!-- 弹层结束 -->
				<div class="col-lg-6 text-left">
					<img src="@/assets/images/bg/mall.png" class="img-fluid" />
				</div>
				<div class="col-lg-5 offset-lg-1 text-white pt-5">
					<p class="font-26">纪元乐购</p>
					<p class="section-subtitle black2 font-secondary margin-top-35">汇聚全球知名宠食品牌</p>
					<p class="font-16 font-secondary black1">宠食及宠物周边用品的专业跨境商品在线销售商城</p>
					<p class="section-subtitle black2 font-secondary">爱宠物，爱生活，尽在纪元乐购</p>
					<p class="section-subtitle black2 font-secondary">点击下方按钮立即体验</p>
					<div class="margin-bottom-25" style="margin-top:50px;">
						<span>
							<el-dropdown split-button type="primary" @click="handleClick">
								扫码体验使用
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-popover:azewmshow>安卓版APP</el-dropdown-item>
									<el-dropdown-item v-popover:iosewmshow>苹果版APP</el-dropdown-item>
									<el-dropdown-item v-popover:xcxewmshow>微信小程序</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</span>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<style scoped>
.el-dropdown {
	vertical-align: top;
}

.el-dropdown+.el-dropdown {
	margin-left: 15px;
}

.el-icon-arrow-down {
	font-size: 12px;
}
</style>
